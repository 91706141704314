import {ScreenTitle} from "../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import ProjectInformationEditorComponent from "./ProjectInformationEditorComponent";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import {Toast} from "../../toast/TokyoToaster";
import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import {ProjectDocumentHistoryModal} from "./ProjectDocumentHistoryModal";
import {ProjectDocumentSelectionModal} from "./ProjectDocumentSelectionModal";
import {StringUtil} from "../../../util/StringUtil";
import {UserSelectionModal} from "../common/UserSelectionModal";
import {EventUtil} from "../../../util/EventUtil";

export const ProjectInformationEditorScreen = (props) => {

    const {id} = useParams();

    const [project, setProject] = useState();
    const [projectNetworkInFlight, setProjectNetworkInFlight] = useState(false);

    const [documents, setDocuments] = useState();
    const [documentNetworkInFlight, setDocumentNetworkInFlight] = useState(false);

    const [projectDocumentTypeID, setProjectDocumentTypeID] = useState(null);
    const [uploadDocument, setUploadDocument] = useState(null);
    const [uploadDocumentShown, setUploadDocumentShown] = useState(false);

    const [assignedUsers, setAssignedUsers] = useState();
    const [userNetworkInFlight, setUserNetworkInFlight] = useState(false);
    const [userPage, setUserPage] = useState(1);
    const [userTotal, setUserTotal] = useState();

    const [assignUserNetworkInFlight, setAssignUserNetworkInFlight] = useState(false);

    const [requestingUser, setRequestingUser] = useState(false);

    useEffect(() => {
        if (id && id !== "new") {
            fetchProjectFromNetwork();
        }
    }, []);

    useEffect(() => {
        if (project) {
            fetchProjectDocumentsFromNetwork();
            fetchProjectUsersFromNetwork();
        }
    }, [project]);

    useEffect(() => {
        if (project) {
            fetchProjectUsersFromNetwork();
        }
    }, [userPage])

    function editorDidCallback(action, data) {
        if (action === "submit") {
            submitProjectOverNetwork(data);
        }
    }

    // DOCUMENTS

    function showDocumentEditor(document) {
        setUploadDocument(document);
        setUploadDocumentShown(true);
    }

    function requestHistoryForType(typeID) {
        setProjectDocumentTypeID(typeID);
    }

    function historyDidCallback(action) {
        if (action === "close") {
            setProjectDocumentTypeID(null);
        }
    }

    function documentUploadDidCallback(action, data) {
        if (action === "close") {
            setUploadDocumentShown(false);
            setUploadDocument(null);

            if (data) {
                fetchProjectDocumentsFromNetwork();
            }
        }
    }

    function documentRowWasClicked(row) {
        if (row !== undefined && row.fileURL !== undefined) {
            window.open(row.fileURL);
        }
    }

    // USERS

    function requestUserRemoval(user) {
        AlertModal.showModal(
            Rosetta.string("site_information.user_remove_confirm"),
            Rosetta.string("site_information.user_remove_confirm_prompt", { user_name : Rosetta.string("common.name_format", {given_name : user.firstName, family_name : user.surname})}),
            [
                {
                    label : Rosetta.string("site_information.user_remove_confirm_confirm"),
                    className : "danger",
                    click : () => {
                        modifyUserAssignment("remove", user.id);
                        AlertModal.dismissModal();
                    }
                },
                {
                    label : Rosetta.string("common.cancel"),
                    click : () => {
                        AlertModal.dismissModal();
                    }
                }
            ]
        );
    }

    function requestUser() {
        if (!userNetworkInFlight) {
            setRequestingUser(true);
        }
    }

    function userModalDidCallback(action, user) {
        if (action === "close") {
            if (user !== null) {
                modifyUserAssignment("assign", user.id);
            }
            setRequestingUser(false);
        }
    }

    function promptDocumentRemoval(document) {
        AlertModal.showModal(
            Rosetta.string("site_information.document_table_remove_title"),
            Rosetta.string("site_information.document_table_remove_desc", { document_title : document.documentType }),
            [
                AlertModal.button(
                    Rosetta.string("site_information.document_table_remove_title"),
                    () => {
                        setProjectDocumentOverNetwork(document);
                        AlertModal.dismissModal();
                    },
                    "danger"
                ),
                AlertModal.button(
                    Rosetta.string("common.cancel"),
                    () => {
                        AlertModal.dismissModal();
                    }
                )
            ]
        );
    }

    function fetchProjectFromNetwork() {
        if (projectNetworkInFlight) return;
        setProjectNetworkInFlight(true);

        let formData = {
            projectID : id
        };

        Axios.post(ENDPOINTS.project.fetchSiteInfo, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setProject(resp.data.siteInfo);
                } else {
                    showError(API.formatError(resp));
                }
                setProjectNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                showError(Rosetta.string("common.error_common_unknown", {error_code : "PDE1000C"}));
                setProjectNetworkInFlight(false);
            });
    }

    function submitProjectOverNetwork(formData) {
        if (projectNetworkInFlight) return;
        setProjectNetworkInFlight(true);

        Axios.post(ENDPOINTS.project.submitProject, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.save_confirmation"),
                        Rosetta.string("site_information.update_success_notice"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                    setProject(resp.data.project);
                } else {
                    showError(API.formatError(resp));
                }
                setProjectNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                showError(Rosetta.string("common.error_common_unknown", {error_code : "PR11000C"}));
                setProjectNetworkInFlight(false);
            });
    }

    function fetchProjectDocumentsFromNetwork() {
        if (documentNetworkInFlight) return;
        setDocumentNetworkInFlight(true);

        let formData = {
            projectID : project.id
        };

        Axios.post(ENDPOINTS.project.getProjectDocuments, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setDocuments(resp.data.data);
                    // Count?
                } else {
                    showError(API.formatError(resp));
                }
                setDocumentNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                showError(Rosetta.string("common.error_common_unknown", {error_code : "PI2000C"}), true);

                setDocumentNetworkInFlight(false);
            });
    }

    function fetchProjectUsersFromNetwork() {
        if (userNetworkInFlight) return;
        setUserNetworkInFlight(true);

        let formData = {
            projectID : project.id,
            page : userPage
        };

        Axios.post(ENDPOINTS.project.getProjectUsers, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setAssignedUsers(resp.data.users);
                    setUserTotal(resp.data.count);
                } else {
                    showError(API.formatError(resp));
                }

                setUserNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                showError(Rosetta.string("common.error_common_unknown", {error_code : "PI2100C"}), true);

                setUserNetworkInFlight(false);
            });
    }

    function modifyUserAssignment(action, userId) {
        if (assignUserNetworkInFlight) return;
        setAssignUserNetworkInFlight(true);

        let formData = {
            projectID : project.id,
            userIDs : [userId]
        };

        let endpoint = ENDPOINTS.project.assignUsers;
        let successMessage = "site_information.user_assign_success";
        if (action === "remove") {
            endpoint = ENDPOINTS.project.removeUsers;
            successMessage = "site_information.user_remove_success";
        }

        Axios.post(endpoint, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string(successMessage),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    fetchProjectUsersFromNetwork();
                } else {
                    showError(API.formatError(resp));
                }
                setAssignUserNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                showError(Rosetta.string("common.error_common_unknown", {error_code : "PUA1000C"}));
                setAssignUserNetworkInFlight(false);
            });
    }

    function setProjectDocumentOverNetwork(document) {
        let data = {
            projectID : (project ? project.id : undefined),
            projectDocumentID : document.documentId
        };

        Axios.post(ENDPOINTS.project.archiveProjectDocument, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("site_information.document_table_remove_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    fetchProjectDocumentsFromNetwork();
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
            })
            .catch((e) => {
              console.log(e);
              Toast.show(
                  Rosetta.string("common.error"),
                  Rosetta.string("common.error_common_unknown", {error_code : "PDA1000C"}),
                  Toast.ERROR,
                  Toast.LONG
              );
            });
    }

    function showError(message, asToast) {
        if (asToast === undefined) {
            asToast = false;
        }

        if (!asToast) {
            AlertModal.showModal(
                Rosetta.string("common.error"),
                message
            );
        } else {
            Toast.show(
                Rosetta.string("common.error"),
                message,
                Toast.ERROR,
                Toast.LONG
            );
        }
    }

    let documentTable = [];
    let userTable = [];

    if (project !== undefined) {
        documentTable = (
            <div className={"row mt-4"}>
                <div className={"col-12 col-md-10"}>
                    <h3>{Rosetta.string("site_information.documents_title")}</h3>
                </div>

                <div className={"col-12 col-md-2 text-end"}>
                    <button className={"btn btn-primary"} onClick={() => showDocumentEditor()}>{Rosetta.string("site_information.document_change_upload")}</button>
                </div>

                <div className={"col-12 mt-1"}>
                    <div className={"table-container"}>
                        <TableComponent
                            className={"clickable"}
                            placeholderMode={documentNetworkInFlight}
                            placeholderRows={5}
                            data={documents}
                            onRowClick={documentRowWasClicked}>

                            <Column name={"documentType"} title={Rosetta.string("site_information.document_table_type")} />
                            <Column name={"fileURL"} title={Rosetta.string("site_information.document_table_path")} render={(data, row) => (
                                <>{ (data !== null) ? StringUtil.truncateCentre(data, 48) : Rosetta.string("site_information.document_table_path_unset") }</>
                            )} />
                            <Column name={"id"} className={"text-end"} render={(data, row) => (
                                <>
                                    <span className={"btn btn-primary me-1"} onClick={(e) => { EventUtil.cancel(e); showDocumentEditor(row); }}>{Rosetta.string("common.edit")}</span>
                                    <span className={"btn btn-danger"} onClick={(e) => { EventUtil.cancel(e); promptDocumentRemoval(row); }}>{Rosetta.string("common.remove")}</span>
                                </>
                            )} />

                        </TableComponent>
                    </div>
                </div>
            </div>
        )

        userTable = (
            <>

                <div className={"row mt-4"}>
                    <div className={"col-8"}>
                        <h3>{Rosetta.string("site_information.users_title")}</h3>
                    </div>

                    <div className={"col-4 text-end"}>
                        <button className={"btn btn-primary"} onClick={() => requestUser()}>{Rosetta.string("site_information.users_action_add")}</button>
                    </div>
                </div>

                <div className={"row mt-1"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                className={"clickable"}
                                placeholderMode={userNetworkInFlight}
                                placeholderRows={5}
                                data={assignedUsers}>

                                <Column name={"id"} title={Rosetta.string("site_information.users_table_name")} render={(data, row) => (
                                    <>{Rosetta.string("common.name_format", { given_name : row.firstName, family_name : row.surname })}</>
                                )} />
                                <Column name={"jobTitle"} title={Rosetta.string("site_information.users_table_job_title")} />
                                <Column name={"roleName"} title={Rosetta.string("site_information.users_table_user_role")} />
                                <Column name={"id"} className={"text-end"} render={(data, row) => (
                                    <>
                                        <button className={"btn btn-danger"} onClick={() => requestUserRemoval(row)}>{Rosetta.string("site_information.users_table_revoke")}</button>
                                    </>
                                )} />

                            </TableComponent>
                        </div>

                        <div className={"ep-table-paginate-container mt-1"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={userPage}
                                totalCount={userTotal}
                                pageSize={20}
                                onClick={(page) => setUserPage(page)} />
                        </div>
                    </div>
                </div>

            </>

        )
    }

    return (
        <div className={"app-screen project-information-screen"}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("site_information.title")} />
                </div>
            </div>

            <ProjectInformationEditorComponent
                project={project}
                blockUI={projectNetworkInFlight}
                callback={editorDidCallback} />

            <ProjectDocumentSelectionModal
                shown={uploadDocumentShown}
                projectID={project ? project.id : undefined}
                document={uploadDocument}
                callback={documentUploadDidCallback} />

            {documentTable}

            {userTable}

            <ProjectDocumentHistoryModal
                projectID={project ? project.id : undefined}
                documentTypeID={projectDocumentTypeID}
                callback={historyDidCallback} />

            <UserSelectionModal
                shown={requestingUser}
                callback={userModalDidCallback} />
        </div>
    )

}