import axios from 'axios'
import Rosetta from "../rosetta/Rosetta";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const API = (function(axios) {
    return {
        setAuthenticationToken : (token) => {
            if (token !== undefined && token !== null) {
                axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            } else if (token === null) {
                delete axios.defaults.headers.common["Authorization"];
            }
        },
        setAuthFailureCallback : (callback) => {
            if (callback instanceof Function) {
                axios.defaults.validateStatus = (status) => {
                    if (status === 401 || status === 403) {
                        callback();
                    } else {
                        return status;
                    }
                }
            }
        },
        parse : (resp) => {
            let out = {
                success : false,
                data : null,
                error : {
                    desc : Rosetta.string("common.error_common_uncoded"),
                    code : -1
                }
            };

            if (resp !== null) {
                out.success = resp.data.success;
                if (out.success) {
                    if (resp.data.hasOwnProperty("data")) {
                        out.data = resp.data.data;
                    } else {
                        out.data = resp.data;
                    }
                } else {
                    if (resp.data.error !== undefined) {
                        out.error.desc = resp.data.error.desc;
                        out.error.code = resp.data.error.code;
                    }
                }
            }

            return out;
        },
        formatError : (resp) => {
            if (!resp.success) {
                return resp.error.desc + " [" + resp.error.code + "]";
            }
            return "";
        },
        objectToPostData : (object) => {
            let keys = Object.keys(object);
            let formData = new FormData();

            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                let value = object[key];
                if (typeof value === 'object' && value !== null) {
                    value = JSON.stringify(value);
                }

                formData.append(key, value);
            }

            return formData;
        }
    }
}) (axios);

export const ENDPOINTS = {
    auth : {
        login : "auth/login",
        requestUserPasswordReset : "auth/requestUserPasswordReset"
    },
    project : {
        userProjects : "project/userProjects",
        fetchSiteInfo : "project/fetchSiteInfo",
        submitProject : "project/submitProject",
        getProjectDocuments : "project/getProjectDocuments",
        getProjectDocumentHistory : "project/getProjectDocumentHistory",
        uploadProjectDocument : "project/uploadProjectDocument",
        getProjectUsers : "project/getProjectUsers",
        assignUsers : "project/assignUsers",
        removeUsers : "project/removeUsers",
        getUserCalendarEvents : "project/getUserCalendarEvents",
        getProjectCalendarSummary : "project/getProjectCalendarSummary",
        getSiteDocumentSections : "project/getSiteDocumentSections",
        getProjectDocumentSection : "project/getProjectDocumentSection",
        submitProjectDocumentSection : "project/submitProjectDocumentSection",
        getProjectDocument : "project/getProjectDocument",
        submitProjectDocument : "project/submitProjectDocument",
        archiveProjectDocument : "project/archiveProjectDocument",
        uploadProjectDocumentFile : "project/uploadProjectDocumentFile",
        getSiteRegisterTable : "project/getSiteRegisterTable",
        getWeather : "project/getWeather",
        getProjectDocumentUpdates : "/project/getProjectDocumentUpdates",
        getDailyDiarySummary : "/project/getDailyDiarySummary",
        getProjects : "project/getProjects",
        setProjectArchiveStatus : "project/setProjectArchiveStatus",
        getSiteInformationExport : "project/getSiteInformationExport",
        getProjectDocumentSections : "project/getProjectDocumentSections",
        getProjectDocumentsAdmin : "project/getProjectDocumentsAdmin",
        submitProjectDocumentSectionAdmin : "project/submitProjectDocumentSectionAdmin",
        getProjectDocumentTypes : "project/getProjectDocumentTypes",
        submitProjectDocumentAdmin : "project/submitProjectDocumentAdmin",
        getProjectCollectionDocumentTypes : "project/getProjectCollectionDocumentTypes",
        submitProjectCollectionDocumentType : "project/submitProjectCollectionDocumentType",
        getProjectCollectionDocuments : "project/getProjectCollectionDocuments",
        submitProjectCollectionDocument : "project/submitProjectCollectionDocument",
        getProjectSignInQR : "project/getProjectSignInQR",
        exportProjectData : "/project/exportProjectData",
        getAppCounts : "/project/getAppCounts"
    },
    user : {
        getUsers : "user/getUsers",
        getUser : "user/getUser",
        getUserRoleTypes : "user/getUserRoleTypes",
        submitUser : "user/submitUser",
        getAdminUsers : "/user/getAdminUsers"
    },
    client : {
        getClient : "client/getClient",
        getClients : "client/getClients",
        submitClient : "client/submitClient",
        uploadClientImage : "client/uploadClientImage"
    },
    projectCollectionDocument : {
        getCollectionDocuments : "projectCollectionDocument/getCollectionDocuments",
        getCollectionDocument : "projectCollectionDocument/getCollectionDocument",
        getCollectionDocumentExport : "projectCollectionDocument/getCollectionDocumentExport"
    },
    projectForm : {
        getProjectForms : "projectForm/getProjectForms",
        getProjectFormsAdmin : "projectForm/getProjectFormsAdmin",
        getProjectSubmittablesAdmin : "projectForm/getProjectSubmittablesAdmin",
        getProjectFormTypes : "projectForm/getProjectFormTypes",
        submitProjectSubmittable : "projectForm/submitProjectSubmittable"
    },
    projectDrawing : {
        getProjectDrawings : "projectDrawing/getProjectDrawings",
        getProjectDrawingSnags : "projectDrawing/getProjectDrawingSnags",
        exportProjectDrawingSnags : "projectDrawing/exportProjectDrawingSnags"
    },
    schedule : {
        getDailySchedule : "schedule/getDailySchedule",
        submitUserCalendarEvent : "project/submitUserCalendarEvent"
    },
    projectHealthSafety : {
        getProjectHealthSafetyVideos : "projectHealthSafety/getProjectHealthSafetyVideos",
        submitHealthSafetyVideo : "projectHealthSafety/submitHealthSafetyVideo",
        submitHealthSafetyVideoThumbnail : "projectHealthSafety/submitHealthSafetyVideoThumbnail",
        deleteHealthSafetyVideo : "projectHealthSafety/deleteHealthSafetyVideo",
        getReports : "projectHealthSafety/getReports",
        approveReport : "projectHealthSafety/approveReport",
        getHealthSafetySummary : "projectHealthSafety/getHealthSafetySummary",
    },
    projectInduction : {
        getProjectInductionData : "projectInduction/getProjectInductionData",
        getProjectInductions : "projectInduction/getProjectInductions",
        uploadInductionFile : "projectInduction/uploadInductionFile",
        deleteProjectInductionDocument : "projectInduction/deleteProjectInductionDocument",
        getProjectInduction : "projectInduction/getProjectInduction",
        approveProjectInduction : "projectInduction/approveProjectInduction",
        declineProjectInduction : "projectInduction/declineProjectInduction"
    },
    personal : {
        getUserNotes : "personal/getUserNotes",
        submitUserNote : "personal/submitUserNote",
        completeUserNote : "personal/completeUserNote",
        deleteUserNote : "personal/deleteUserNote"
    },
    subscription : {
        getSubscriptionTypes : "subscription/getSubscriptionTypes",
        submitSubscription : "subscription/submitSubscription",
        getPaymentDetails : "subscription/getPaymentDetails",
        checkPaymentComplete : "subscription/checkPaymentComplete",
        checkEmailInUse : "subscription/checkEmailInUse",
        submitContactForm : "subscription/submitContactForm",
        getSubscription : "subscription/getSubscription",
        assignSubscriptionSlot : "subscription/assignSubscriptionSlot",
        unassignSubscriptionSlot : "subscription/unassignSubscriptionSlot",
        getUpgradeOptions : "subscription/getUpgradeOptions",
        getSubscriptionAction : "subscription/submitSubscriptionAction",
        setSubscriptionAutoRenewal : "subscription/setSubscriptionAutoRenewal",
        setDefaultPaymentMethod : "/subscription/setDefaultPaymentMethod"
    },
    form : {
        getFormTemplates : "/form/getFormTemplates",
        getForm : "form/getForm",
        submitFormTemplate : "form/submitFormTemplate",
        getFormTypes : "form/getFormTypes"
    }
};
