import {Offcanvas, OffcanvasActions, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import {useEffect, useState} from "react";
import Rosetta from "../../../rosetta/Rosetta";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Chronos} from "../../../chronos/Chronos";
import {CommonUtil} from "../../../util/CommonUtil";
import AlertModal from "../../alertmodal/AlertModal";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";
import {EventUtil} from "../../../util/EventUtil";
import {StringUtil} from "../../../util/StringUtil";
import WindowUtil from "../../../util/WindowUtil";

export const SubscriptionPaymentMethodsModal = (props) => {

    const {shown} = props;
    const {defaultPaymentMethodID} = props;
    const {paymentMethods} = props;
    const {address} = props;
    const {callback} = props;

    const [forceDismiss, setForceDismiss] = useState(false);
    const [callbackData, setCallbackData] = useState(undefined);

    const [newCardDefault, setNewCardDefault] = useState(true);

    const [creatingCard, setCreatingCard] = useState(false);
    const [defaultNetworkInFlight, setDefaultNetworkInFlight] = useState(false);

    const [setupIntentID, setSetupIntentID] = useState(null);

    const [billingAddressSame, setBillingAddressSame] = useState(false);
    const [billingName, setBillingName] = useState("");
    const [billingAddress1, setBillingAddress1] = useState("");
    const [billingAddress2, setBillingAddress2] = useState("");
    const [billingCity, setBillingCity] = useState("");
    const [billingCounty, setBillingCounty] = useState("");
    const [billingPostcode, setBillingPostcode] = useState("");

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (shown) {
            if (address) {
                setBillingAddressSame(true);
                setBillingName(address.name);
                setBillingAddress1(address.address1);
                setBillingAddress2(address.address2);
                setBillingCity(address.city);
                setBillingCounty(address.county);
                setBillingPostcode(address.postcode);
            }

            WindowUtil.lockBodyScroll();
        } else {
            setForceDismiss(false);
            setCallbackData(undefined);

            WindowUtil.unlockBodyScroll();
        }
    }, [shown]);

    function handleCallback(action) {
        if (callback) {
            if (action === OffcanvasActions.CLOSE && !creatingCard && !defaultNetworkInFlight) {
                callback(action, callbackData);
            }
        }
    }

    // NETWORK

    // Creating a new card
    async function submitCardForm(e) {
        EventUtil.cancel(e);

        if (creatingCard) return;
        setCreatingCard(true);

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type : "card",
            card : elements.getElement(CardElement),
            billing_details : {
                name : billingName,
                address : CommonUtil.implode([billingAddress1, billingAddress2, billingCity, billingCounty, billingPostcode])
            }
        });

        if (paymentMethods) {
            setDefaultCardOverNetwork(paymentMethod.id, true);
        } else {
            let errorMessage = Rosetta.string("payment_method.error_card");
            if (error) {
                errorMessage = error.message;
            }
            AlertModal.showError(errorMessage);
        }

        setCreatingCard(false);
    }

    function setDefaultCardOverNetwork(paymentMethodID, setupCard) {
        if (defaultNetworkInFlight) return;
        setDefaultNetworkInFlight(true);

        let data = {
            paymentMethodID,
            setupCard,
            setupIntentID
        };

        Axios.post(ENDPOINTS.subscription.setDefaultPaymentMethod, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.complete) {
                        Toast.show(
                            Rosetta.string("common.success"),
                            Rosetta.string("payment_method.default_set_success"),
                            Toast.SUCCESS,
                            Toast.LONG
                        );

                        setCallbackData(true);
                        setForceDismiss(true);
                    } else {
                        if (resp.data.requiresAction) {
                            setSetupIntentID(resp.data.setupIntentId);
                            stripe.confirmCardSetup(resp.data.clientSecret)
                                .then((r) => {
                                    if (!r.error) {
                                        // Call endpoint again to confirm
                                        setDefaultCardOverNetwork(
                                            paymentMethodID,
                                            setupCard
                                        );
                                    } else {
                                        AlertModal.showError()
                                    }
                                    setDefaultNetworkInFlight(false);
                                })
                                .catch((e) => {
                                    console.log(e);
                                    setDefaultNetworkInFlight(false);
                                    AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "SUBP2103C" }));
                                });
                        }
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setDefaultNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setDefaultNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", {error_code : "PMD1000C"}));
            });
    }

    // RENDER

    if (!shown) return [];

    let paymentMethodElems = (
        <div className={"col-12 text-center"}>
            <em>{Rosetta.string("payment_method.editor_empty")}</em>
        </div>
    );

    if (paymentMethods) {
        if (paymentMethods.length > 0) {
            paymentMethodElems = [];

            paymentMethods.forEach((method, index) => {
                let cardExpiry = Chronos.parse(method.expiryYear + "-" + StringUtil.leftPad(method.expiryMonth, 2, "0") + "-01");
                let expiryDate = (cardExpiry ? cardExpiry.format("MM/yy") : "?");

                cardExpiry.add(1, Chronos.MONTHS);

                let cardIsValid = true;

                let expiryClass = "";
                if (Chronos.now().isAfter(cardExpiry.getDate())) {
                    expiryClass = "text-danger";
                    cardIsValid = false;
                }

                let onClick = null;

                let itemActions = [];

                if (creatingCard || defaultNetworkInFlight) {
                    itemActions = (
                        <span className={"badge bg-success"}>{Rosetta.string("payment_method.editor_updating")}</span>
                    )
                } else if (cardIsValid) {
                    if (method.id === defaultPaymentMethodID) {
                        itemActions = (
                            <span className={"badge bg-success"}>{Rosetta.string("payment_method.editor_is_default")}</span>
                        )
                    } else {
                        itemActions = (
                            <span className={"badge bg-primary"}>{Rosetta.string("payment_method.editor_set_default")}</span>
                        )
                        onClick = () => setDefaultCardOverNetwork(method.id);
                    }
                }

                paymentMethodElems.push(
                    <li className={"list-group-item d-flex justify-content-between align-items-center clickable"} onClick={onClick}>
                        <div>
                            <div><strong>{Rosetta.string("payment_method.editor_card_index", { index : (index + 1) })}</strong></div>
                            <div>{Rosetta.string("subscription.subscription_default_card_ending_in", { lastFour : method.lastFour })}</div>
                            <div className={expiryClass}>{Rosetta.string("subscription.subscription_default_card_expires", { expiry : expiryDate })}</div>
                        </div>
                        {itemActions}
                    </li>
                )
            });

            paymentMethodElems = (
                <div className={"col-12"}>
                    <ul className={"list-group"}>
                        {paymentMethodElems}
                    </ul>
                </div>
            )
        }
    }

    let billingAddressFields = [];

    billingAddressFields.push(
        <div className={"row mt-2"}>
            <div className={"col-12"}>
                <h4>{Rosetta.string("payment_method.editor_card_address")}</h4>
            </div>
        </div>
    )

    if (address) {
        billingAddressFields.push(
            <div className={"row"}>
                <div className={"col-12"}>
                    <label><input type={"checkbox"} checked={billingAddressSame} onChange={(e) => setBillingAddressSame(e.target.checked)} /> {Rosetta.string("payment_method.editor_card_address_same")}</label>
                </div>
            </div>
        )
    }

    if (!billingAddressSame) {
        billingAddressFields.push(
            <>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("subscription.company_name")}</label>
                        <input type={"text"} className={"form-control"} value={billingName} onChange={(e) => setBillingName(e.target.value)} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("subscription.company_address_line1")}</label>
                        <input type={"text"} className={"form-control"} value={billingAddress1} onChange={(e) => setBillingAddress1(e.target.value)} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("subscription.company_address_line2")}</label>
                        <input type={"text"} className={"form-control"} value={billingAddress2} onChange={(e) => setBillingAddress2(e.target.value)} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("subscription.company_address_city")}</label>
                        <input type={"text"} className={"form-control"} value={billingCity} onChange={(e) => setBillingCity(e.target.value)} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("subscription.company_address_county")}</label>
                        <input type={"text"} className={"form-control"} value={billingCounty} onChange={(e) => setBillingCounty(e.target.value)} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("subscription.company_address_postcode")}</label>
                        <input type={"text"} className={"form-control"} value={billingPostcode} onChange={(e) => setBillingPostcode(e.target.value)} />
                    </div>
                </div>
            </>
        )
    }

    let createCardButton = (<button className={"btn btn-success"}>{Rosetta.string("common.save")}</button>);
    if (creatingCard || defaultNetworkInFlight) {
        createCardButton = (<button className={"btn btn-secondary"}>{Rosetta.string("common.please_wait")}</button>);
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("payment_method.editor_title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <h4>{Rosetta.string("payment_method.editor_card_title")}</h4>
                </div>
            </div>

            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"card"}>
                        <div className={"card-body"}>

                            <form onSubmit={submitCardForm}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <label>{Rosetta.string("payment_methods.editor_card")}</label>
                                        <div className={"form-control"}>
                                            <CardElement />
                                        </div>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <label><input type={"checkbox"} checked={newCardDefault} onChange={(e) => setNewCardDefault(e.target.checked)} /> {Rosetta.string("payment_method.editor_set_default")}</label>
                                    </div>
                                </div>

                                {billingAddressFields}

                                <div className={"row mt-2"}>
                                    <div className={"col-12 text-center"}>
                                        {createCardButton}
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <h4>{Rosetta.string("payment_method.editor_saved_cards")}</h4>
                </div>
            </div>

            <div className={"row mt-2"}>
                {paymentMethodElems}
            </div>

        </Offcanvas>
    )

}