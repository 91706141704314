import {CalendarComponent} from "../../calendar/CalendarComponent";
import {useEffect, useState} from "react";
import {Chronos} from "../../../chronos/Chronos";
import {ScreenTitle} from "../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

import arrowRight from "../../../assets/images/arrow_right.svg";
import {ImageUtil} from "../../../util/ImageUtil";

import "./CalendarScreen.css";
import {CalendarEventModal} from "./CalendarEventModal";
import {CalendarEventEditorModal} from "./CalendarEventEditorModal";
import {PlaceholderText} from "../../placeholder/PlaceholderText";
import {Navigator} from "../../../util/Navigator";

export const CalendarScreen = (props) => {

    const todayDate = Chronos.now().getDate();

    const [monthDate, setMonthDate] = useState(todayDate);
    const [selectedDate, setSelectedDate] = useState(todayDate);

    const [events, setEvents] = useState([]);
    const [eventNetworkInFlight, setEventNetworkInFlight] = useState(false);

    const [summary, setSummary] = useState([]);
    const [summaryNetworkInFlight, setSummaryNetworkInFlight] = useState(false);

    const [calendarEventShown, setCalendarEventShown] = useState(false);
    const [selectedCalendarEventID, setSelectedCalendarEventID] = useState(null);
    const [eventEditorShown, setEventEditorShown] = useState(false);

    useEffect(() => {
        fetchEventDataFromNetwork();
        fetchCalendarSummaryFromNetwork();
    }, []);

    useEffect(() => {
        fetchCalendarSummaryFromNetwork();
    }, [monthDate])

    useEffect(() => {
        fetchEventDataFromNetwork();
    }, [selectedDate]);

    function calendarDidCallback(action, date) {
        if (action === "change") {
            setSelectedDate(date);
        } else if (action === "month-change") {
            setMonthDate(date);
        }
    }

    function showCalendarEventDetail(event) {
        if (event) {
            setSelectedCalendarEventID(event.id);
            setCalendarEventShown(true);
        }
    }

    function calendarEventDidCallback(action, data) {
        if (action === "close") {
            setCalendarEventShown(false);
        }
    }

    function summonEventEditor() {
        setEventEditorShown(true);
    }

    function eventEditorDidCallback(action, data) {
        if (action === "close") {
            setEventEditorShown(false);
        } else if (action === "submit") {
            if (data) {
                setEventEditorShown(false);

                fetchCalendarSummaryFromNetwork();
                fetchEventDataFromNetwork();
            }
        }
    }

    function getSelectedDateHeader() {
        let specialPrefix = null;
        let formattedDate = Chronos.with(selectedDate).format("EEE, d/M/yy");

        if (Chronos.now().isSameDay(selectedDate)) {
            specialPrefix = Rosetta.string("time.today");
        } else if (Chronos.now().add(-1, Chronos.DAYS).isSameDay(selectedDate)) {
            specialPrefix = Rosetta.string("time.yesterday");
        } else if (Chronos.now().add(1, Chronos.DAYS).isSameDay(selectedDate)) {
            specialPrefix = Rosetta.string("time.tomorrow");
        }

        if (specialPrefix !== null) {
            formattedDate = Rosetta.string("calendar.selected_date_special", {
                day : specialPrefix,
                date : formattedDate
            });
        }

        return formattedDate;
    }

    function createCalendarEventElement(clickable, elements) {
        let chevron = [];
        let className = "";
        if (clickable) {
            chevron = (<div className={"chevron"} style={{backgroundImage : ImageUtil.background(arrowRight)}} />);
            className = " clickable";
        }

        return (
            <div className={"calendar-event" + className}>
                <div className={"content-area"}>
                    {elements}
                </div>
                {chevron}
            </div>
        );
    }

    function fetchEventDataFromNetwork() {
        if (eventNetworkInFlight) return;
        setEventNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let date = Chronos.with(selectedDate).startOfDay().format("t");

        let formData = {
            projectID : (project ? project.id : undefined),
            date
        };

        Axios.post(ENDPOINTS.schedule.getDailySchedule, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setEvents(resp.data);
                } else {
                    // TODO Error
                }
                setEventNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setEventNetworkInFlight(false);
            });
    }

    function fetchCalendarSummaryFromNetwork() {
        if (summaryNetworkInFlight) return;
        setSummaryNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            startDate : Chronos.with(monthDate).startOfMonth().format("t"),
            endDate : Chronos.with(monthDate).endOfMonth().format("t")
        };

        Axios.post(ENDPOINTS.project.getProjectCalendarSummary, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let eventData = [];

                    // Convert data to conform with calendar expectations
                    for (let i = 0; i < resp.data.events.length; i++) {
                        let event = resp.data.events[i];

                        eventData.push({
                            date : Chronos.withTimestampSeconds(event.date).getDate(),
                            elements : (<span className={"badge bg-info"}>{Rosetta.string("calendar.day_events", { count : event.count }, event.count)}</span>)
                        });
                    }

                    setSummary(eventData);
                } else {
                    // TODO Error
                }
                setSummaryNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSummaryNetworkInFlight(false);
            });
    }

    const todayEvents = [];

    if (!eventNetworkInFlight) {
        if (events) {
            if (events.hasOwnProperty("inspectionsRequired")) {
                todayEvents.push(createCalendarEventElement(false, (
                    <>
                        <div
                            className={"event-title"}>{Rosetta.string("calendar.inspections_required", {count: events.inspectionsRequired.count}, events.inspectionsRequired.count)}</div>
                        <div className={"event-body"}>
                            <ul>
                                {events.inspectionsRequired.forms.map((form) => (
                                    <li>{form.title}</li>
                                ))}
                            </ul>
                        </div>
                    </>
                )));
            }

            if (events.hasOwnProperty("snagCounts")) {
                if (events.snagCounts.openCount > 0) {
                    todayEvents.push(createCalendarEventElement(true, (
                        <div
                            className={"event-title"} onClick={() => Navigator.navigate("/snagging/")}>{Rosetta.string("calendar.snags_open", {count: events.snagCounts.openCount}, events.snagCounts.openCount)}</div>
                    )))
                }

                if (events.snagCounts.unactionedCount > 0) {
                    todayEvents.push(createCalendarEventElement(true, (
                        <div
                            className={"event-title"} onClick={() => Navigator.navigate("/snagging/")}>{Rosetta.string("calendar.snags_unactioned", {count: events.snagCounts.unactionedCount}, events.snagCounts.unactionedCount)}</div>
                    )))
                }

                if (events.snagCounts.unsignedCount > 0) {
                    todayEvents.push(createCalendarEventElement(true, (
                        <div
                            className={"event-title"} onClick={() => Navigator.navigate("/snagging/")}>{Rosetta.string("calendar.snags_unsigned", {count: events.snagCounts.unsignedCount}, events.snagCounts.unsignedCount)}</div>
                    )))
                }

                if (events.snagCounts.completedCount > 0) {
                    todayEvents.push(createCalendarEventElement(true, (
                        <div
                            className={"event-title"} onClick={() => Navigator.navigate("/snagging/")}>{Rosetta.string("calendar.snags_completed", {count: events.snagCounts.completedCount}, events.snagCounts.completedCount)}</div>
                    )))
                }
            }

            if (events.hasOwnProperty("expiringPermits")) {
                if (events.expiringPermits.length > 0) {
                    todayEvents.push(createCalendarEventElement(true, (
                        <div
                            className={"event-title"} onClick={() => Navigator.navigate("/forms/")}>{Rosetta.string("calendar.permits_expiring", {count: events.expiringPermits.length}, events.expiringPermits.length)}</div>
                    )))
                }
            }

            if (events.hasOwnProperty("calendarEvents")) {
                if (events.calendarEvents.events.length > 0) {
                    events.calendarEvents.events.forEach((event) => {
                        todayEvents.push(createCalendarEventElement(true, (
                            <div onClick={() => showCalendarEventDetail(event)}>
                                <div className={"event-title"}>{event.title}</div>
                                <div className={"event-body"}>{event.description}</div>
                            </div>
                        )))
                    });
                }
            }

            if (events.hasOwnProperty("dailyDiaryRequiresSignOff") && events.dailyDiaryRequiresSignOff) {
                todayEvents.push(createCalendarEventElement(true, (
                    <div className={"event-title"} onClick={() => Navigator.navigate("/daily-diary/")}>{Rosetta.string("calendar.daily_diary_sign")}</div>
                )))
            }
        }

        if (todayEvents.length === 0) {
            todayEvents.push(
                <div className={"empty-message"}>{Rosetta.string("calendar.empty_message")}</div>
            )
        }
    } else {
        todayEvents.push(createCalendarEventElement(false, (
            <>
                <div><PlaceholderText /></div>
                <div className={"mt-1"}><PlaceholderText /></div>
            </>
        )));
        todayEvents.push(createCalendarEventElement(false, (
            <PlaceholderText />
        )));
        todayEvents.push(createCalendarEventElement(false, (
            <PlaceholderText />
        )));
        todayEvents.push(createCalendarEventElement(false, (
            <>
                <div><PlaceholderText /></div>
                <div className={"mt-1"}><PlaceholderText /></div>
            </>
        )));
        todayEvents.push(createCalendarEventElement(false, (
            <PlaceholderText />
        )));
        todayEvents.push(createCalendarEventElement(false, (
            <PlaceholderText />
        )));
    }

    return (
        <div className={"app-screen calendar-screen"}>
            <div className={"row"}>
                <div className={"col-12 col-md-8"}>
                    <ScreenTitle title={Rosetta.string("calendar.title")} />
                </div>

                <div className={"col-12 col-md-4 text-end"}>
                    <button className={"btn btn-primary"} onClick={() => summonEventEditor()}>{Rosetta.string("calendar.add_event")}</button>
                </div>
            </div>

            <div className={"animate-screen-content"}>

                <div className={"row mt-4"}>
                    <div className={"col-12 col-lg-6 mb-4"}>
                        <CalendarComponent
                            data={summary}
                            selectedDate={selectedDate}
                            monthDate={monthDate}
                            endDate={todayDate}
                            callback={calendarDidCallback} />
                    </div>

                    <div className={"col-12 col-lg-6"}>

                        <div className={"card"}>
                            <div className={"card-header calendar-event-date"}>
                                {getSelectedDateHeader()}
                            </div>
                            <div className={"card-body"}>
                                {todayEvents}
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <CalendarEventModal
                shown={calendarEventShown}
                eventID={selectedCalendarEventID}
                callback={calendarEventDidCallback} />

            <CalendarEventEditorModal
                shown={eventEditorShown}
                callback={eventEditorDidCallback} />
        </div>
    )

}