import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useEffect, useState} from "react";

import "./PaymentFormComponent.css";
import Rosetta from "../../../rosetta/Rosetta";
import {EventUtil} from "../../../util/EventUtil";
import {CommonUtil} from "../../../util/CommonUtil";
import AlertModal from "../../alertmodal/AlertModal";

export const PaymentFormComponent = (props) => {

    const {title} = props;
    const {price} = props;
    const {paymentMethods} = props;

    const [selectedPaymentMethodID, setSelectedPaymentMethodID] = useState("new");

    const [billingName, setBillingName] = useState("");
    const [billingAddressSame, setBillingAddressSame] = useState(false);
    const [billingAddress1, setBillingAddress1] = useState("");
    const [billingAddress2, setBillingAddress2] = useState("");
    const [billingCity, setBillingCity] = useState("");
    const [billingCounty, setBillingCounty] = useState("");
    const [billingPostcode, setBillingPostcode] = useState("");

    const [canSubmit, setCanSubmit] = useState(true);

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (paymentMethods) {
            if (paymentMethods.length > 0) {
                setSelectedPaymentMethodID(paymentMethods[0].id);
            }
        }
    }, []);

    async function submitForm(e) {
        EventUtil.cancel(e);
        if (!canSubmit) return;

        if (selectedPaymentMethodID === "new") {
            let address = {};

            const requiredFields = [
                { keyName : "paymentName", data : billingName, label : Rosetta.string("subscription.payment_card_name_on_card") },
                { keyName : "paymentAddressLine1", data : billingAddress1, label : Rosetta.string("subscription.company_address_line1") },
                { keyName : "paymentAddressLine2", data : billingAddress2, label : Rosetta.string("subscription.company_address_line2") },
                { keyName : "paymentAddressCity", data : billingCity, label : Rosetta.string("subscription.company_address_city") },
                { keyName : "paymentAddressCounty", data : billingCounty, label : Rosetta.string("subscription.company_address_county") },
                { keyName : "paymentAddressPostcode", data : billingPostcode, label : Rosetta.string("subscription.company_address_postcode") }
            ];

            for (let i = 0; i < requiredFields.length; i++) {
                let field = requiredFields[i];

                if (field.data.trim() === "") {
                    AlertModal.showError(Rosetta.string("validation.missing_property", { label : field.label }));
                    return;
                }

                address[field.keyName] = field.data;
            }

            setCanSubmit(false);
            const {error, paymentMethod} = await stripe.createPaymentMethod({
                type : "card",
                card : elements.getElement(CardElement),
                billing_details : {
                    name : billingName,
                    address : CommonUtil.implode([billingAddress1, billingAddress2, billingCity, billingCounty, billingPostcode])
                }
            });

            if (error) {
                AlertModal.showError(error);
            } else if (paymentMethod) {
                handleCallback("submit",  {
                    paymentMethodID : paymentMethod.id,
                    address
                });
            }
            setCanSubmit(true);
        } else {
            handleCallback("submit", {
                paymentMethodID: selectedPaymentMethodID
            });
        }
    }

    function handleCallback(action, data) {
        if (props.callback !== undefined) {
            props.callback(action, data);
        }
    }

    let savedCardElems = [];
    if (paymentMethods && paymentMethods.length > 0) {
        paymentMethods.forEach((method) => {
            savedCardElems = (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <label className={"payment-method"}>
                            <input type={"radio"} value={method.id} checked={selectedPaymentMethodID === method.id} onChange={(e) => setSelectedPaymentMethodID(e.target.value)} />

                            <span className={"payment-method-details"}>
                                <strong>{method.type}</strong>
                                <div>{Rosetta.string("payment_form.card_last_four", { lastFour : method.lastFour })}</div>
                                <div>{Rosetta.string("payment_form.card_expires", { date : "TODO" })}</div>
                            </span>
                        </label>
                    </div>
                </div>
            )
        })
    }

    let newCardElem = [];
    if (selectedPaymentMethodID === "new") {
        newCardElem = (
            <>
                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("subscription.payment_card_details")}</label>
                        <div className={"form-control card-container"}>
                            <CardElement />
                        </div>
                    </div>
                </div>

                <div className={"row mt-2 justify-content-center"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("subscription.payment_card_name_on_card")}</label>
                        <input type={"text"} className={"form-control"} value={billingName} onChange={(e) => setBillingName(e.target.value)} />
                    </div>
                </div>


                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("subscription.payment_card_billing_address")}</label>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("subscription.company_address_line1")}</label>
                        <input type={"text"} className={"form-control"} value={billingAddress1} disabled={billingAddressSame} onChange={(e) => setBillingAddress1(e.target.value)} />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("subscription.company_address_line2")}</label>
                        <input type={"text"} className={"form-control"} value={billingAddress2} disabled={billingAddressSame} onChange={(e) => setBillingAddress2(e.target.value)} />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("subscription.company_address_city")}</label>
                        <input type={"text"} className={"form-control"} value={billingCity} disabled={billingAddressSame} onChange={(e) => setBillingCity(e.target.value)} />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("subscription.company_address_county")}</label>
                        <input type={"text"} className={"form-control"} value={billingCounty} disabled={billingAddressSame} onChange={(e) => setBillingCounty(e.target.value)} />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("subscription.company_address_postcode")}</label>
                        <input type={"text"} className={"form-control"} value={billingPostcode} disabled={billingAddressSame} onChange={(e) => setBillingPostcode(e.target.value)} />
                    </div>
                </div>
            </>
        )
    }

    newCardElem = (
        <>
            <div className={"row"}>
                <div className={"col-12"}>
                    <label className={"payment-method"}>
                        <input type={"radio"} value={"new"} checked={selectedPaymentMethodID === "new"} onChange={(e) => setSelectedPaymentMethodID("new")} />

                        <span className={"payment-method-details"}>
                            <strong>{Rosetta.string("payment_form.card_new_card")}</strong>
                        </span>
                    </label>
                </div>
            </div>

            {newCardElem}
        </>
    );

    return (
        <form onSubmit={submitForm}>
            <div className={"card payment-form-component"}>
                <div className={"card-body"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"payment-form-title"}>{title}</div>
                            <div className={"payment-form-price"}>{price}</div>
                        </div>
                    </div>

                    {savedCardElems}

                    {newCardElem}

                    <div className={"row mt-4"}>
                        <div className={"col-12 text-center"}>
                            <input type={"submit"} className={"btn btn-primary"} disabled={!canSubmit} value={Rosetta.string("payment_form.pay_now")} />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )

}