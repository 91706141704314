import {ScreenTitle} from "../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {SegmentTab, SegmentTabBar} from "../../segmenttab/SegmentTabBar";
import {DiarySummaryComponent} from "./DiarySummaryComponent";
import {DiaryRegisterComponent} from "./DiaryRegisterComponent";
import {Chronos} from "../../../chronos/Chronos";
import {FormsListScreen} from "../forms/FormsListScreen";
import {DiaryHealthSafetyComponent} from "./DiaryHealthSafetyComponent";
import {DiarySnaggingComponent} from "./DiarySnaggingComponent";
import {DiaryDocumentComponent} from "./DiaryDocumentComponent";
import {DiaryNotesComponent} from "./DiaryNotesComponent";
import {useLocation} from "react-router-dom";
import {Navigator} from "../../../util/Navigator";

export const DailyDiaryTabs = {
    TAB_SUMMARY : 1,
    TAB_SITE_REGISTER : 2,
    TAB_FORMS : 3,
    TAB_HEALTH_SAFETY : 4,
    TAB_SNAGGING : 5,
    TAB_DOCUMENTS : 6,
    TAB_OTHER : 7
}

export const DailyDiaryScreen = () => {

    const TAB_SUMMARY = DailyDiaryTabs.TAB_SUMMARY;
    const TAB_SITE_REGISTER = DailyDiaryTabs.TAB_SITE_REGISTER;
    const TAB_FORMS = DailyDiaryTabs.TAB_FORMS;
    const TAB_HEALTH_SAFETY = DailyDiaryTabs.TAB_HEALTH_SAFETY;
    const TAB_SNAGGING = DailyDiaryTabs.TAB_SNAGGING;
    const TAB_DOCUMENTS = DailyDiaryTabs.TAB_DOCUMENTS;
    const TAB_OTHER = DailyDiaryTabs.TAB_OTHER;

    const URI_SUMMARY = "summary";
    const URI_REGISTER = "register";
    const URI_FORMS = "forms";
    const URI_HEALTH_SAFETY = "health_safety";
    const URI_SNAGGING = "snagging";
    const URI_DOCUMENTS = "documents";
    const URI_OTHER = "other";

    const [hasInitialised, setInitialised] = useState(false);

    const [date, setDate] = useState();
    const [selectedTab, setSelectedTab] = useState(TAB_SUMMARY);

    useEffect(() => {
        locationDidChange();
        setInitialised(true);
    }, []);
    
    useEffect(() => {
        if (hasInitialised) {
            navigateToState();
        }
    }, [date, selectedTab]);

    function locationDidChange() {
        const hashTab = Navigator.getHashParam("t", null);
        if (hashTab !== null) {
            setSelectedTab(getTabForUri(hashTab));
        }

        const uriDate = Navigator.getHashParam("d", null);
        if (!date && uriDate !== null) {
            setDate(uriDate);
        }
    }

    function onTabSelected(action, tab) {
        if (action === "select") {
            setSelectedTab(tab);
        }
    }

    function navigateToState(inDate, inTab) {
        if (!inDate) {
            inDate = date;
        }

        if (!inTab) {
            inTab = selectedTab;
        }

        let hashValues = "";

        if (inDate) {
            if (hashValues === "") {
                hashValues += "#";
            }

            hashValues += "d=" + inDate;
        }

        if (inTab) {
            if (hashValues === "") {
                hashValues += "#";
            } else {
                hashValues += "&";
            }

            hashValues += "t=" + getUriForTab(inTab);
        }

        Navigator.navigate("/daily-diary" + hashValues);
    }

    function getTabForUri(tag) {
        switch (tag) {
            case URI_SUMMARY: return TAB_SUMMARY;
            case URI_REGISTER: return TAB_SITE_REGISTER;
            case URI_FORMS: return TAB_FORMS;
            case URI_HEALTH_SAFETY: return TAB_HEALTH_SAFETY;
            case URI_SNAGGING: return TAB_SNAGGING;
            case URI_DOCUMENTS: return TAB_DOCUMENTS;
            case URI_OTHER: return TAB_OTHER;
            default: console.log("Unknown tag: " + tag);
        }
        return TAB_SUMMARY;
    }

    function getUriForTab(tab) {
        switch (tab) {
            case TAB_SUMMARY: return URI_SUMMARY;
            case TAB_SITE_REGISTER: return URI_REGISTER;
            case TAB_FORMS: return URI_FORMS;
            case TAB_HEALTH_SAFETY: return URI_HEALTH_SAFETY;
            case TAB_SNAGGING: return URI_SNAGGING;
            case TAB_DOCUMENTS: return URI_DOCUMENTS;
            case TAB_OTHER: return TAB_OTHER;
            default: console.log("Unknown tab: " + tab);
        }
        return URI_SUMMARY;
    }

    function summaryDidCallback(action, data) {
        if (action === "navigate") {
            setSelectedTab(data);
        }
    }

    const chronosDate = date ? Chronos.parse(date) : Chronos.now();
    let useDate = null;
    if (chronosDate && chronosDate.isValid()) {
        useDate = chronosDate.getDate();
    }

    let tabContent = [];
    switch (selectedTab) {
        case TAB_SUMMARY: tabContent = <DiarySummaryComponent date={useDate} callback={summaryDidCallback} />; break;
        case TAB_SITE_REGISTER: tabContent = <DiaryRegisterComponent date={useDate} />; break;
        case TAB_FORMS: tabContent = <FormsListScreen date={useDate} showTitle={false} />; break;
        case TAB_HEALTH_SAFETY: tabContent = <DiaryHealthSafetyComponent date={useDate} />; break;
        case TAB_SNAGGING: tabContent = <DiarySnaggingComponent date={useDate} />; break;
        case TAB_DOCUMENTS: tabContent = <DiaryDocumentComponent date={useDate} />; break;
        case TAB_OTHER: tabContent = <DiaryNotesComponent date={useDate} />; break;
        default : console.log("Unknown tab: " + selectedTab);
    }

    return (
        <div className={"app-screen daily-diary-screen"}>
            <div className={"row"}>
                <div className={"col-12 col-md-6 col-lg-8"}>
                    <ScreenTitle title={Rosetta.string("diary.title")} />
                </div>
                <div className={"col-12 col-md-6 col-lg-4 text-end"}>
                    <input type={"date"} className={"form-control"} value={date} onChange={(e) => setDate(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-4"}>
                <SegmentTabBar selected={selectedTab} callback={onTabSelected}>
                    <SegmentTab id={TAB_SUMMARY} label={Rosetta.string("diary.tab_summary")} />
                    <SegmentTab id={TAB_SITE_REGISTER} label={Rosetta.string("diary.tab_site_register")} />
                    <SegmentTab id={TAB_FORMS} label={Rosetta.string("diary.tab_forms")} />
                    <SegmentTab id={TAB_HEALTH_SAFETY} label={Rosetta.string("diary.tab_health_safety")} />
                    <SegmentTab id={TAB_SNAGGING} label={Rosetta.string("diary.tab_snagging")} />
                    <SegmentTab id={TAB_DOCUMENTS} label={Rosetta.string("diary.tab_documents")} />
                    <SegmentTab id={TAB_OTHER} label={Rosetta.string("diary.tab_other")} />
                </SegmentTabBar>
            </div>

            {tabContent}

        </div>
    )

}