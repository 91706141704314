import {WeatherWidgetComponent} from "./WeatherWidgetComponent";
import {useEffect, useState} from "react";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";
import Rosetta from "../../../rosetta/Rosetta";
import {CommonUtil} from "../../../util/CommonUtil";
import {OrganiserNoteWidget} from "../organiser/OrganiserNoteWidget";

import "./DiarySummaryComponent.css";

import indicatorIcon from "../../../assets/images/arrow_right.svg";
import {ImageUtil} from "../../../util/ImageUtil";
import {Chronos} from "../../../chronos/Chronos";
import {useLocation} from "react-router-dom";
import {PlaceholderText} from "../../placeholder/PlaceholderText";
import {Navigator} from "../../../util/Navigator";
import {DailyDiaryScreen, DailyDiaryTabs} from "./DailyDiaryScreen";

export const DiarySummaryComponent = (props) => {

    const {date} = props;

    const [summaryData, setSummaryData] = useState([]);
    const [summaryNetworkInFlight, setSummaryNetworkInFlight] = useState(false);

    useEffect(() => {
        fetchSummaryDataFromNetwork();
    }, []);

    useEffect(() => {
        setSummaryNetworkInFlight(false);
        fetchSummaryDataFromNetwork();
    }, [date]);

    function handleCallback(action, data) {
        if (props.callback !== undefined) {
            props.callback(action, data);
        }
    }

    function showError(message) {
        Toast.show(
            Rosetta.string("common.error"),
            message,
            Toast.ERROR,
            Toast.LONG
        );
    }

    function generateSummaryWidget(label, click) {
        return (
            <div className={"summary-widget clickable mb-2"} onClick={click}>
                <span className={"summary-widget-title"}>{label}</span>
                <span className={"summary-widget-indicator"} style={{backgroundImage : ImageUtil.background(indicatorIcon)}} />
            </div>
        )
    }

    function fetchSummaryDataFromNetwork() {
        if (summaryNetworkInFlight) return;
        setSummaryNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        const chronosDate = Chronos.with(date);
        let useDate = null;
        if (chronosDate && chronosDate.isValid()) {
            useDate = chronosDate.seconds();
        }

        let formData = {
            projectID : (project ? project.id : undefined),
            date : useDate
        };

        Axios.post(ENDPOINTS.project.getDailyDiarySummary, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setSummaryData(resp.data);
                } else {
                    showError(API.formatError(resp));
                }
                setSummaryNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSummaryNetworkInFlight(false);
                showError(Rosetta.string("common.error_common_unknown", {error_code : "DDS1000"}));
            });
    }

    let countWidgets = [];
    let noteElems = [];
    let signOffElem = [];

    if (summaryData) {
        let registerCount = CommonUtil.getOrDefault(summaryData, "registerCount", 0);
        if (registerCount > 0) {
            countWidgets.push(generateSummaryWidget(Rosetta.string("diary.summary_register", {count : registerCount}, registerCount), () => {
                handleCallback("navigate", DailyDiaryTabs.TAB_SITE_REGISTER);
            }));
        }

        let alarmsActivated = CommonUtil.getOrDefault(summaryData, "alarmsActivated", 0);
        if (alarmsActivated > 0) {
            countWidgets.push(generateSummaryWidget(Rosetta.string("diary.summary_alarm", { count : alarmsActivated }, alarmsActivated), () => {
                handleCallback("navigate", DailyDiaryTabs.TAB_HEALTH_SAFETY);
            }));
        }

        let healthSafetyEvents = CommonUtil.getOrDefault(summaryData, "healthSafetyEvents", 0);
        if (healthSafetyEvents > 0) {
            countWidgets.push(generateSummaryWidget(Rosetta.string("diary.summary_hs_events", {count : healthSafetyEvents}, healthSafetyEvents), () => {
                handleCallback("navigate", DailyDiaryTabs.TAB_HEALTH_SAFETY);
            }));
        }

        let formCount = CommonUtil.getOrDefault(summaryData, "formCount", 0);
        if (formCount > 0) {
            countWidgets.push(generateSummaryWidget(Rosetta.string("diary.summary_forms", {count : formCount}, formCount), () => {
                handleCallback("navigate", DailyDiaryTabs.TAB_FORMS);
            }));
        }

        let documentUpdateCount = CommonUtil.getOrDefault(summaryData, "documentUpdateCount", 0);
        if (documentUpdateCount > 0) {
            countWidgets.push(generateSummaryWidget(Rosetta.string("diary.summary_documents", {count : documentUpdateCount}, documentUpdateCount), () => {
                handleCallback("navigate", DailyDiaryTabs.TAB_DOCUMENTS);
            }));
        }

        if (summaryData.hasOwnProperty("snagCount")) {
            if (summaryData.snagCount > 0) {
                countWidgets.push(generateSummaryWidget(Rosetta.string("diary.snags", {count : summaryData.snagCount}, summaryData.snagCount), () => {
                    handleCallback("navigate", DailyDiaryTabs.TAB_SNAGGING);
                }));
            }
        }

        if (summaryData.hasOwnProperty("notes")) {
            if (summaryData.notes.length > 0) {
                noteElems = summaryData.notes.map((note) => (
                    <div className={"col-12 col-md-6"}>
                        <OrganiserNoteWidget
                            note={note}
                            showActions={false} />
                    </div>
                ));
            }

            noteElems = (
                <div className={"row mt-4"}>
                    {noteElems}
                </div>
            );
        }

        if (summaryData.hasOwnProperty("signatures")) {
            signOffElem = summaryData.signatures.map((signOff) => (
                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-success"}>
                            {Rosetta.string("diary.summary_signoff", { time : Chronos.withTimestampSeconds(signOff.dateCreated).format("HH:mm") })}
                        </div>
                    </div>
                </div>
            ))
        }
    }

    if (countWidgets.length === 0) {
        if (!summaryNetworkInFlight) {
            countWidgets = (
                <div className={"col-12"}>
                    <div className={"empty-message"}>{Rosetta.string("diary.summary_events_empty")}</div>
                </div>
            );
        } else {
            for (let i = 0; i < 5; i++) {
                countWidgets.push(generateSummaryWidget(<PlaceholderText />));
            }
        }
    }

    return (
        <div className={"animate-screen-content diary-summary-component mt-4"}>
            <div className={"row"}>
                <div className={"col-12 col-md-6"}>
                    <WeatherWidgetComponent />
                </div>

                <div className={"col-12 col-md-6"}>
                    {countWidgets}
                </div>
            </div>

            {noteElems}
            {signOffElem}

        </div>
    )

}