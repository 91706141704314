import {useEffect, useState} from "react";
import {FilterText} from "../../filter/FilterText";
import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import Rosetta from "../../../rosetta/Rosetta";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import {ScreenTitle} from "../screenTitle/ScreenTitle";
import {Navigator} from "../../../util/Navigator";

export const UsersListScreen = (props) => {

    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [usersCount, setUsersCount] = useState(0);
    const [userNetworkInFlight, setUserNetworkInFlight] = useState(false);

    const [filterKeyword, setFilterKeyword] = useState("");

    useEffect(() => {
        fetchUsersFromNetwork();
    }, []);

    useEffect(() => {
        fetchUsersFromNetwork();
    }, [filterKeyword, page]);

    function tableRowWasClicked(row) {
        if (row) {
            moveToEditor(row.id);
        }
    }

    function moveToEditor(id) {
        if (!id) {
            id = "new";
        }
        Navigator.navigate("/settings/users/" + id);
    }

    function fetchUsersFromNetwork() {
        if (userNetworkInFlight) return;
        setUserNetworkInFlight(true);

        let formData = {
            keyword : filterKeyword,
            page
        };

        Axios.post(ENDPOINTS.user.getUsers, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setUsers(resp.data.users);
                    setUsersCount(resp.data.count);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setUserNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setUserNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "U1000C"}));
            })
    }

    return (
        <div className={"app-screen diary-register-component"}>

            <div className={"row"}>
                <div className={"col-12 col-md-8"}>
                    <ScreenTitle title={Rosetta.string("system_settings.option_users")} />
                </div>

                <div className={"col-12 col-md-4 text-end"}>
                    <button className={"btn btn-primary"} onClick={() => moveToEditor()}>{Rosetta.string("system_settings.user_add")}</button>
                </div>
            </div>

            <div className={"animate-screen-content"}>
                <div className={"row mt-4"}>

                    <div className={"col-12 col-md-4"}>
                        <FilterText callback={setFilterKeyword} />
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                className={"clickable"}
                                data={users}
                                placeholderMode={userNetworkInFlight}
                                placeholderRows={10}
                                onRowClick={tableRowWasClicked}>

                                <Column name={"firstName"} title={Rosetta.string("register.table_first_name")} />
                                <Column name={"surname"} title={Rosetta.string("register.table_last_name")} />
                                <Column name={"userRoleName"} title={Rosetta.string("register.table_user_type")} />
                                <Column name={"jobTitle"} title={Rosetta.string("register.table_job_title")} />
                                <Column name={"company"} title={Rosetta.string("register.table_company")} />

                            </TableComponent>

                            <div className={"ep-table-paginate-container mt-1"}>
                                <div className={"push"} />
                                <TablePaginator
                                    page={page}
                                    totalCount={usersCount}
                                    pageSize={20}
                                    onClick={setPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}