import {ImageUtil} from "../../../util/ImageUtil";
import Rosetta from "../../../rosetta/Rosetta";
import {useState} from "react";

import IconCall from "../../../assets/images/call.svg";
import IconEmail from "../../../assets/images/mail.svg";

import "./SubscriptionBespokeFormComponent.css";
import Validator from "../../../util/Validator";
import AlertModal from "../../alertmodal/AlertModal";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

export const SubscriptionBespokeFormComponent = (props) => {

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [companyName, setCompanyName] = useState();
    const [details, setDetails] = useState();

    const [formNetworkInFlight, setFormNetworkInFlight] = useState(false);
    const [formComplete, setFormComplete] = useState(false);
    const [formReference, setFormReference] = useState("");

    function submitFormOverNetwork() {
        if (formNetworkInFlight) return;

        const validationData = {
            name,
            email,
            phoneNumber,
            companyName,
            details
        };

        const validationResult = Validator.validateCreateFormData(validationData, [
            Validator.rule("name", "string", Rosetta.string("subscription.bespoke_form_name"), "contactName"),
            Validator.rule("email", "string", Rosetta.string("subscription.bespoke_form_email"), "contactEmail"),
            Validator.rule("phoneNumber", "string", Rosetta.string("subscription.bespoke_form_phone"), "contactPhone"),
            Validator.rule("companyName", "string", Rosetta.string("subscription.bespoke_form_company"), "companyName"),
            Validator.rule("details", "string", Rosetta.string("subscription.bespoke_form_additional"), "message"),
        ]);

        if (!validationResult.success) {
            AlertModal.showError(validationResult.error);
            return;
        }

        setFormNetworkInFlight(true);

        Axios.post(ENDPOINTS.subscription.submitContactForm, validationResult.formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setFormComplete(true);
                    setFormReference(resp.data.reference);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setFormNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setFormNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_generic_unknown", { error_code : "SUBC1000C" }));
            });
    }

    let contactForm = [];
    if (!formComplete) {
        contactForm = (
            <>
                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <label>{Rosetta.string("subscription.bespoke_form_name")}</label>
                        <input type={"text"} className={"form-control"} value={name} onChange={(e) => setName(e.target.value)} />
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <label>{Rosetta.string("subscription.bespoke_form_email")}</label>
                        <input type={"text"} className={"form-control"} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12 col-md-6"}>
                        <label>{Rosetta.string("subscription.bespoke_form_phone")}</label>
                        <input type={"text"} className={"form-control"} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <label>{Rosetta.string("subscription.bespoke_form_company")}</label>
                        <input type={"text"} className={"form-control"} value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("subscription.bespoke_form_additional")}</label>
                        <textarea className={"form-control"} value={details} onChange={(e) => setDetails(e.target.value)} />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12 text-center"}>
                        <button className={"btn btn-primary"} disabled={formNetworkInFlight} onClick={() => submitFormOverNetwork()}>{Rosetta.string("common.submit")}</button>
                    </div>
                </div>
            </>
        )
    } else {
        contactForm = (
            <>
                <div className={"row"}>
                    <div className={"col-12 text-center"}>
                        <div className={"form-title"}>{Rosetta.string("subscription.bespoke_complete_title")}</div>
                        <div className={"form-subtitle"}>{Rosetta.string("subscription.bespoke_complete_message")}</div>
                        <div className={"mt-2"}>{Rosetta.string("subscription.bespoke_complete_reference", {reference : formReference})}</div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className={"animate-screen-content subscription-bespoke-form-component"}>
            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <div className={"form-title"}>{Rosetta.string("subscription.bespoke_title")}</div>
                    <div className={"form-subtitle"}>{Rosetta.string("subscription.bespoke_subtitle")}</div>
                </div>
            </div>

            <div className={"row justify-content-center mt-4"}>
                <div className={"col-12 col-md-10 col-md-8"}>
                    <div className={"contact-clip form-control"}>
                        <div className={"contact-clip-icon"} style={{backgroundImage : ImageUtil.background(IconEmail)}} />
                        <div className={"contact-clip-label"}>contact@constructapps.co.uk</div>
                        <a className={"btn btn-primary"} href={"mailto:contact@constructapps.co.uk"}>{Rosetta.string("subscription.bespoke_action_email")}</a>
                    </div>

                    <div className={"contact-clip form-control"}>
                        <div className={"contact-clip-icon"} style={{backgroundImage : ImageUtil.background(IconCall)}} />
                        <div className={"contact-clip-label"}>01234567890</div>
                        <a className={"btn btn-primary"} href={"tel:01234567890"}>{Rosetta.string("subscription.bespoke_action_call")}</a>
                    </div>
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <div className={"form-subtitle"}>{Rosetta.string("subscription.bespoke_or")}</div>
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <div className={"form-subtitle"}>{Rosetta.string("subscription.bespoke_manual")}</div>
                </div>
            </div>

            <div className={"row justify-content-center mt-4"}>
                <div className={"col-12 col-md-10 col-md-8"}>
                    <div className={"card"}>
                        <div className={"card-body"}>

                            {contactForm}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}