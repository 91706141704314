import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";
import {DataManager} from "../../../data/DataManager";
import {Navigator} from "../../../util/Navigator";
import {ScreenTitle} from "../screenTitle/ScreenTitle";

export const ProjectSelectionScreen = () => {

    const [projectNetworkInFlight, setProjectNetworkInFlight] = useState(false);
    const [projects, setProjects] = useState();
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setPage] = useState(1);

    useEffect(() => {
        fetchProjectsFromNetwork();
    }, []);

    useEffect(() => {
        fetchProjectsFromNetwork();
    }, [currentPage]);

    function tableRowWasClicked(row) {
        if (row !== undefined && row !== null) {
            DataManager.setSelectedProject(row);
            Navigator.navigate("/");
        }
    }

    function fetchProjectsFromNetwork() {
        if (projectNetworkInFlight) return;
        setProjectNetworkInFlight(true);

        let formData = {
            page : currentPage
        }

        Axios.post(ENDPOINTS.project.userProjects, formData)
            .then((r) => {
                let resp = API.parse(r);

                if (resp.success) {
                    setProjects(resp.data.projects);
                    setTotalCount(resp.data.count);
                } else {
                    showError(API.formatError(resp));
                }

                setProjectNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                showError(Rosetta.string("common.error_common_unknown", { error_code : "PS1000C"}));
                setProjectNetworkInFlight(false);
            });
    }

    function showError(message) {
        Toast.show(
            Rosetta.string("common.error"),
            message,
            Toast.ERROR,
            Toast.LONG
        );
    }

    return (
        <div className={"app-screen project-selection-screen"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("project_selection.title")} />
                </div>
            </div>

            <div className={"animate-screen-content"}>

                <div className={"table-container"}>
                    <TableComponent
                        className={"clickable"}
                        data={projects}
                        placeholderMode={projectNetworkInFlight}
                        placeholderRows={15}
                        onRowClick={tableRowWasClicked}>

                        <Column name={"project_name"} title={Rosetta.string("project_selection.table_project_name")} />
                        <Column name={"project_code"} className={"text-center"} title={Rosetta.string("project_selection.table_project_code")} />
                        <Column name={"postcode"} className={"text-center"} title={Rosetta.string("project_selection.table_project_address")} />
                        <Column name={"id"} className={"text-center"} render={(data, row) => {
                            return (<button className={"btn btn-primary"}>{Rosetta.string("common.select")}</button>);
                        }} />

                    </TableComponent>
                </div>

                <div className={"ep-table-paginate-container mt-1"}>
                    <div className={"push"} />
                    <TablePaginator
                        page={currentPage}
                        totalCount={totalCount}
                        pageSize={20}
                        onClick={(page) => setPage(page)} />
                </div>

            </div>

        </div>
    )

}