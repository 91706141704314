import {useEffect, useState} from "react";
import Rosetta from "../../rosetta/Rosetta";
import {Chronos} from "../../chronos/Chronos";

import "./CalendarComponent.css";
import {EmergingButton} from "../button/EmergingButton";
import {ImageUtil} from "../../util/ImageUtil";

import arrowLeft from "../../assets/images/arrow_left.svg";
import arrowRight from "../../assets/images/arrow_right.svg";

export const CalendarDay = (date, elements) => {
    return { date, elements };
}

export const CalendarComponent = (props) => {

    const {selectedDate} = props;
    const {monthDate} = props;
    const {startDate} = props;
    const {endDate} = props;
    const {data} = props;

    function handleCallback(action, date) {
        if (props.callback !== undefined) {
            props.callback(action, date);
        }
    }

    function changeMonth(direction) {
        let nextDate = Chronos.with(monthDate).add(direction, Chronos.MONTHS);

        if (startDate && nextDate.isBefore(endDate)) {
            return;
        }

        if (endDate && nextDate.isAfter(endDate)) {
            return;
        }

        handleCallback("month-change", nextDate.getDate());
    }

    function createDateHeading(label) {
        return (
            <div className={"calendar-day"}>{label}</div>
        )
    }

    function createPaddingElement() {
        return (
            <div className={"calendar-pad"} />
        )
    }

    function createDateElement(date) {
        let selDate = Chronos.now().getDate();
        if (selectedDate) {
            selDate = selectedDate;
        }

        let curDate = Chronos.with(date);

        let classExtra = "";
        if (curDate.isSameDay(selDate)) {
            classExtra = " active";
        }

        let elements = [];
        if (data) {
            console.log(curDate.format("dd/MM/yyyy"));
            for (let i = 0; i < data.length; i++) {
                console.log("Match date: " + Chronos.with(data[i].date).format("dd/MM/yyyy") + " ? " + (curDate.isSameDay(data[i].date)));
                if (curDate.isSameDay(data[i].date)) {
                    console.log("ELEMENTS:");
                    console.log(data[i].elements);
                    elements = data[i].elements;
                }
            }
        }

        let clickEvent = () => handleCallback("change", date);
        if ((startDate && curDate.isBefore(startDate)) || (endDate && curDate.isAfter(endDate))) {
            classExtra += " inactive";
            clickEvent = undefined;
        }

        return (
            <div className={"calendar-date" + classExtra} onClick={clickEvent}>
                <div className={"date-title"}>
                    {Chronos.with(date).format("dd")}
                </div>
                {elements}
            </div>
        )
    }

    if (monthDate === null) return [];

    let gridContents = [];

    gridContents.push(createDateHeading(Rosetta.string("days.mon")));
    gridContents.push(createDateHeading(Rosetta.string("days.tue")));
    gridContents.push(createDateHeading(Rosetta.string("days.wed")));
    gridContents.push(createDateHeading(Rosetta.string("days.thu")));
    gridContents.push(createDateHeading(Rosetta.string("days.fri")));
    gridContents.push(createDateHeading(Rosetta.string("days.sat")));
    gridContents.push(createDateHeading(Rosetta.string("days.sun")));

    const useDate = Chronos.with(monthDate).startOfMonth();
    const endOfMonth = useDate.endOfMonth();

    // If the month does not start on a Monday, work out how many spaces we need to pad by
    // to make the days align in the grid
    let startPad = 6 - useDate.getDate().getDay() - 1;
    if (startPad < 0) {
        startPad = 6;
    }
    for (let i = 0; i < startPad; i++) {
        gridContents.push(createPaddingElement());
    }

    // Now iterate through all days in the month
    let dateIteration = Chronos.with(useDate.getDate());
    let daysToCount = endOfMonth.difference(useDate.getDate(), Chronos.DAYS) + 1;
    for (let i = 1; i <= daysToCount; i++) {
        gridContents.push(createDateElement(dateIteration.getDate()));
        dateIteration.add(1, Chronos.DAYS);
    }

    // If the month doesn't end on a Sunday, work out how many spaces to add padding for
    let remainingSpace = 49 - gridContents.length; // 7 columns * 7 rows
    for (let i = 0; i < remainingSpace; i++) {
        gridContents.push(createPaddingElement());
    }

    return (
        <div className={"calendar-component"}>
            <div className={"date-navigation"}>
                <EmergingButton pill={true}>
                    <div className={"end-button"} onClick={() => changeMonth(-1)} style={{backgroundImage : ImageUtil.background(arrowLeft)}} />
                </EmergingButton>
                <div className={"date-text-container"}>
                    <EmergingButton pill={true} className={"ms-1"}>
                        <div className={"date-text"}><span>{Chronos.with(monthDate).format("MMMM yyyy")}</span></div>
                    </EmergingButton>
                </div>
                <EmergingButton pill={true} className={"ms-1"}>
                    <div className={"end-button"} onClick={() => changeMonth(1)} style={{backgroundImage : ImageUtil.background(arrowRight)}} />
                </EmergingButton>
            </div>

            <div className={"date-grid"}>
                {gridContents}
            </div>
        </div>
    )

}