import {useEffect, useState} from "react";
import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import Rosetta from "../../../rosetta/Rosetta";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import WindowUtil from "../../../util/WindowUtil";
import Validator from "../../../util/Validator";
import {Toast} from "../../toast/TokyoToaster";
import {LoadingSpinner} from "../../loading/LoadingSpinner";

export const ProjectDocumentSectionEditorModal = (props) => {

    const {shown} = props;
    const {sectionID} = props;
    const {parentSectionID} = props;

    const [section, setSection] = useState(null);
    const [sectionNetworkInFlight, setSectionNetworkInFlight] = useState(false);

    const [sectionTitle, setSectionTitle] = useState("");

    const [forceDismiss, setForceDismiss] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
            if (sectionID) {
                fetchSectionFromNetwork();
            }
        } else {
            WindowUtil.unlockBodyScroll();
            setSection(null);
            setSectionTitle("");
            setSectionNetworkInFlight(false);
            setForceDismiss(false);
        }
    }, [shown]);

    useEffect(() => {
        populateIntoState(section);
    }, [section]);

    function handleCallback(action, data) {
        if (props.callback !== undefined) {
            props.callback(action, data);
        }
    }

    function populateIntoState(section) {
        if (section) {
            setSectionTitle(section.title);
        } else {
            setSectionTitle("");
        }
    }

    function fetchSectionFromNetwork() {
        if (sectionNetworkInFlight) return;
        setSectionNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            parentSectionID,
            projectDocumentSectionID : sectionID
        };

        Axios.post(ENDPOINTS.project.getProjectDocumentSection, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setSection(resp.data.section);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSectionNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSectionNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PDSE1000C" }));
            });
    }

    function submitSectionOverNetwork() {
        let validationResult = Validator.validateCreateFormData({
            sectionTitle
        }, [
            Validator.rule("sectionTitle", "string", Rosetta.string("document_management.section_editor_section_title"), "title")
        ]);

        if (!validationResult.success) {
            setError(validationResult.error);
            return;
        }

        setError(null); // Clear error

        if (sectionNetworkInFlight) return;
        setSectionNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        if (project === null) return;

        let formData = validationResult.formData;
        formData.append("projectID", project.id);
        if (sectionID) formData.append("id", sectionID);
        if (parentSectionID) formData.append("parentSectionID", parentSectionID);

        Axios.post(ENDPOINTS.project.submitProjectDocumentSection, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("document_management.section_editor_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                    setForceDismiss(true);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSectionNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSectionNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PDSE2000C"}));
            });
    }

    // RENDER

    if (!shown) return [];

    let errorElem = [];
    if (error !== null) {
        errorElem = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"alert alert-error"}>
                        {error}
                    </div>
                </div>
            </div>
        );
    }

    let submitButton = (
        <div className={"row mt-4"}>
            <div className={"col-12 text-center"}>
                <button className={"btn btn-primary"} onClick={() => submitSectionOverNetwork()}>{Rosetta.string("common.save")}</button>
            </div>
        </div>
    );
    if (sectionNetworkInFlight) {
        submitButton = (
            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <LoadingSpinner small={true} />
                </div>
            </div>
        )
    }

    return (
        <div className={"project-document-section-editor-modal"}>

            <Offcanvas
                shown={true}
                title={Rosetta.string("document_management.section_editor_title")}
                gravity={OffcanvasGravity.END}
                forceDismiss={forceDismiss}
                callback={handleCallback}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("document_management.section_editor_section_title")}</label>
                        <input type={"text"} className={"form-control"} value={sectionTitle} onChange={(e) => setSectionTitle(e.target.value)} />
                    </div>
                </div>

                {submitButton}

                {errorElem}

            </Offcanvas>
        </div>
    )

}