import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {DataManager} from "../../../../data/DataManager";
import {useParams} from "react-router-dom";
import {ContentOrPlaceholder} from "../../../placeholder/ContentOrPlaceholder";
import {Chronos} from "../../../../chronos/Chronos";
import {FormViewComponent} from "../../forms/FormViewComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import AlertModal from "../../../alertmodal/AlertModal";
import {Navigator} from "../../../../util/Navigator";
import {Toast} from "../../../toast/TokyoToaster";
import {ImageUtil} from "../../../../util/ImageUtil";
import {Lightbox, LightboxImage} from "../../../lightbox/Lightbox";

import "./InductionDetailScreen.css";
import {AppCounts} from "../../../../util/AppCounts";

export const InductionDetailScreen = (props) => {

    const {id} = useParams();

    const [induction, setInduction] = useState(null);
    const [inductionNetworkInFlight, setInductionNetworkInFlight] = useState(false);
    const [actionNetworkInFlight, setActionNetworkInFlight] = useState(false);

    const [lightboxShown, setLightboxShown] = useState(false);
    const [lightboxImages, setLightboxImages] = useState([]);
    const [lightboxImage, setLightboxImage] = useState(null);

    useEffect(() => {
        fetchInductionFromNetwork();
    }, []);

    useEffect(() => {
        fetchInductionFromNetwork()
    }, [id]);

    function showLightbox(image) {
        let images = [];

        if (induction) {
            if (induction.images) {
                images = induction.images.map((image) => LightboxImage(image.imagePath, image.thumbPath))
            }
        }

        setLightboxImages(images);
        setLightboxImage(LightboxImage(image.imagePath, image.thumbPath));
        setLightboxShown(true);
    }

    function lightboxDidCallback(action, data) {
        if (action === "close") {
            setLightboxShown(false);
        }
    }

    function promptForAction() {
        AlertModal.showModal(
            Rosetta.string("health_safety.induction_detail_action"),
            "",
            [
                AlertModal.button(
                    Rosetta.string("health_safety.induction_detail_action_approve"),
                    () =>  {
                        submitActionOverNetwork(true);
                        AlertModal.dismissModal();
                    },
                    "success"
                ),
                AlertModal.button(
                    Rosetta.string("health_safety.induction_detail_action_decline"),
                    () =>  {
                        submitActionOverNetwork(false);
                        AlertModal.dismissModal();
                    },
                    "danger"
                ),
                AlertModal.button(
                    Rosetta.string("common.cancel"),
                    () => AlertModal.dismissModal()
                )
            ]
        )
    }

    function fetchInductionFromNetwork() {
        if (inductionNetworkInFlight) return;
        setInductionNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            projectInductionID : id,
            fetchForm : true
        };

        Axios.post(ENDPOINTS.projectInduction.getProjectInduction, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setInduction(resp.data.induction);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setInductionNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setInductionNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "HSIV1000C"}));
            });
    }

    function submitActionOverNetwork(accept) {
        if (actionNetworkInFlight) return;
        setActionNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            projectInductionID : id
        };

        let url = ENDPOINTS.projectInduction.approveProjectInduction;
        if (!accept) {
            url = ENDPOINTS.projectInduction.declineProjectInduction;
        }

        Axios.post(url, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let toastMessage = "";

                    if (accept) {
                        induction.approved = 1;
                        toastMessage = Rosetta.string("health_safety.induction_detail_action_approve_success");
                    } else {
                        Navigator.goBack();
                        toastMessage = Rosetta.string("health_safety.induction_detail_action_decline_success");
                    }

                    Toast.show(
                        Rosetta.string("common.success"),
                        toastMessage,
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    AppCounts.refreshAppCounts(project.id);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }

                setActionNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setActionNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "HSPA1000C" }));
            })
    }

    let formElems = [];

    if (induction && induction.hasOwnProperty("forms")) {
        formElems = induction.forms.map((form) => (
            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-md-8 col-lg-6"}>
                    <FormViewComponent
                        callback={() => {}}
                        placeholderMode={inductionNetworkInFlight}
                        form={form} />
                </div>
            </div>
        ))
    }

    let approvalButton = [];
    if (induction && parseInt(induction.approved) === 0) {
        approvalButton = (
            <button className={"btn btn-primary"} onClick={() => promptForAction()}>{Rosetta.string("health_safety.induction_detail_action")}</button>
        );
    }

    let medicalInfoElem = [];
    if (induction) {
        if (induction.data) {
            if (induction.data.hasOwnProperty("medicalInformation") && induction.data.medicalInformation !== null) {
                medicalInfoElem = (
                    <div className={"row mt-2"}>
                        <div className={"col-12"}>
                            <label>{Rosetta.string("health_safety.induction_detail_medical_information")}</label>
                            <div className={"form-control"}>{induction.data.medicalInformation}</div>
                        </div>
                    </div>
                )
            }
        }
    }

    let imageElems = [];
    if (induction) {
        if (induction.hasOwnProperty("images") !== null && induction.images.length > 0) {
            imageElems = (
                <div className={"image-thumbs"}>
                    {
                        induction.images.map((image) => (
                            <div className={"image-thumb image-thumbnail"} onClick={() => showLightbox(image)} style={{backgroundImage: ImageUtil.background(image.thumbPath)}}/>
                        ))
                    }
                </div>
            )
        }
    }

    return (
        <div className={"app-screen induction-detail-screen"}>
            <div className={"row"}>
                <div className={"col-12 col-md-8"}>
                    <ScreenTitle title={Rosetta.string("health_safety.induction_detail_title")} />
                </div>
                <div className={"col-12 col-md-4 text-end"}>
                    {approvalButton}
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <h3>
                        <ContentOrPlaceholder showContent={!inductionNetworkInFlight}>
                            {(induction ? Rosetta.string("common.name_format", { given_name : induction.firstName, family_name : induction.surname }) : "")}
                        </ContentOrPlaceholder>
                    </h3>

                    <div className={"mt-2"}>
                        <ContentOrPlaceholder showContent={!inductionNetworkInFlight}>
                            {Rosetta.string("health_safety.induction_detail_date", { date : (induction ? Chronos.withTimestampSeconds(induction.dateCreated).format("dd/MM/yyyy HH:mm") : "")})}
                        </ContentOrPlaceholder>
                    </div>
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className={"row"}>
                                <div className={"col-12 col-md-6 mt-2"}>
                                    <label>{Rosetta.string("health_safety.induction_detail_dob")}</label>
                                    <ContentOrPlaceholder showContent={!inductionNetworkInFlight}>
                                        <div>{(induction ? Chronos.withTimestampSeconds(induction.data.dateOfBirth).format("dd/MM/yyyy") : "")}</div>
                                    </ContentOrPlaceholder>
                                </div>

                                <div className={"col-12 col-md-6 mt-2"}>
                                    <label>{Rosetta.string("health_safety.induction_detail_occupation")}</label>
                                    <ContentOrPlaceholder showContent={!inductionNetworkInFlight}>
                                        <div>{(induction ? induction.data.occupation : "")}</div>
                                    </ContentOrPlaceholder>
                                </div>

                                <div className={"col-12 col-md-6 mt-2"}>
                                    <label>{Rosetta.string("health_safety.induction_detail_employer")}</label>
                                    <ContentOrPlaceholder showContent={!inductionNetworkInFlight}>
                                        <div>{(induction ? induction.data.employer : "")}</div>
                                    </ContentOrPlaceholder>
                                </div>

                                <div className={"col-12 col-md-6 mt-2"}>
                                    <label>{Rosetta.string("health_safety.induction_detail_nok_name")}</label>
                                    <ContentOrPlaceholder showContent={!inductionNetworkInFlight}>
                                        <div>{(induction ? induction.data.emergencyContactName : "")}</div>
                                    </ContentOrPlaceholder>
                                </div>

                                <div className={"col-12 col-md-6 mt-2"}>
                                    <label>{Rosetta.string("health_safety.induction_detail_nok_number")}</label>
                                    <ContentOrPlaceholder showContent={!inductionNetworkInFlight}>
                                        <div>{(induction ? induction.data.emergencyContactNumber : "")}</div>
                                    </ContentOrPlaceholder>
                                </div>

                                <div className={"col-12 col-md-6 mt-2"}>
                                    <label>{Rosetta.string("health_safety.induction_detail_medical_condition")}</label>
                                    <ContentOrPlaceholder showContent={!inductionNetworkInFlight}>
                                        <div>{(induction ? Rosetta.string(induction.data.medicalCondition ? "common.true" : "common.false") : "")}</div>
                                    </ContentOrPlaceholder>
                                </div>

                                {medicalInfoElem}

                                {imageElems}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {formElems}

            <Lightbox
                shown={lightboxShown}
                images={lightboxImages}
                initialImage={lightboxImage}
                callback={lightboxDidCallback} />

        </div>
    )

}