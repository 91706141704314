import {ScreenTitle} from "../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import {Toast} from "../../toast/TokyoToaster";
import Validator from "../../../util/Validator";
import {LoadingSpinner} from "../../loading/LoadingSpinner";

export const UserDetailScreen = (props) => {

    const {id} = useParams();

    const [user, setUser] = useState(null);
    const [userNetworkInFlight, setUserNetworkInFlight] = useState(false);
    const [userSubmitNetworkInFlight, setUserSubmitNetworkInFlight] = useState(false);

    const [userRoles, setUserRoles] = useState([]);
    const [rolesNetworkInFlight, setRolesNetworkInFlight] = useState(false);

    const [userFirstName, setUserFirstName] = useState();
    const [userSurname, setUserSurname] = useState();
    const [userEmailAddress, setUserEmailAddress] = useState();
    const [userTelephone, setUserTelephone] = useState();
    const [userRoleId, setUserRoleId] = useState();
    const [userJobTitle, setUserJobTitle] = useState();
    const [userCompany, setUserCompany] = useState();

    useEffect(() => {
        fetchUserRolesFromNetwork();
        if (id && id !== "new") {
            fetchUserFromNetwork();
        }
    }, []);

    function populateUserIntoState(user) {
        setUser(user);
        setUserFirstName(user.firstName);
        setUserSurname(user.surname);
        setUserEmailAddress(user.emailAddress);
        setUserTelephone(user.telephone);
        setUserRoleId(user.userRoleId);
        setUserJobTitle(user.jobTitle);
        setUserCompany(user.company);
    }

    function fetchUserFromNetwork() {
        if (userNetworkInFlight) return;
        setUserNetworkInFlight(true);

        let formData = {
            id
        };

        Axios.post(ENDPOINTS.user.getUser, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    populateUserIntoState(resp.data.user);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setUserNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setUserNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", {error_code : "US1000C"}));
            });
    }

    function submitUserOverNetwork() {
        if (userSubmitNetworkInFlight) return;

        const validationResult = Validator.validateCreateFormData({
            userFirstName,
            userSurname,
            userEmailAddress,
            userTelephone,
            userRoleId,
            userJobTitle,
            userCompany
        }, [
            Validator.rule("userFirstName", "string", Rosetta.string("system_settings.user_first_name"), "firstName"),
            Validator.rule("userSurname", "string", Rosetta.string("system_settings.user_surname"), "surname"),
            Validator.rule("userEmailAddress", "string", Rosetta.string("system_settings.user_email"), "emailAddress"),
            Validator.rule("userRoleId", "int", Rosetta.string("system_settings.user_user_type"), "userRoleId"),
            Validator.rule("userTelephone", "string", Rosetta.string("system_settings.user_phone_number"), "telephoneNumber", true),
            Validator.rule("userJobTitle", "string", Rosetta.string("system_settings.user_profession"), "jobTitle", true),
            Validator.rule("userCompany", "string", Rosetta.string("system_settings.user_company"), "company", true)
        ]);

        if (!validationResult.success) {
            AlertModal.showError(validationResult.error);
            return;
        }

        setUserSubmitNetworkInFlight(true);

        const formData = validationResult.formData;
        if (user) {
            formData.append("id", user.id);
        }

        Axios.post(ENDPOINTS.user.submitUser, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("system_settings.user_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setUserSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setUserSubmitNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", {error_code : "USS1000C"}));
            });
    }

    function fetchUserRolesFromNetwork() {
        if (rolesNetworkInFlight) return;
        setRolesNetworkInFlight(true);

        Axios.get(ENDPOINTS.user.getUserRoleTypes)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setUserRoles(resp.data.userRoles);
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setRolesNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setRolesNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "US2000C" }));
            });
    }

    let submitButton = (<button className={"btn btn-primary"} onClick={() => submitUserOverNetwork()}>{Rosetta.string("common.save")}</button>);
    if (userSubmitNetworkInFlight) {
        submitButton = (<LoadingSpinner small={true} inline={true} />);
    }

    return (
        <div className={"app-screen diary-register-component"}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("system_settings.user_detail")} />
                </div>
            </div>

            <div className={"animate-screen-content"}>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                {Rosetta.string("system_settings.user_personal_details")}
                            </div>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("system_settings.user_first_name")}</label>
                                        <input type={"text"} className={"form-control"} value={userFirstName} onChange={(e) => setUserFirstName(e.target.value)} />
                                    </div>

                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("system_settings.user_surname")}</label>
                                        <input type={"text"} className={"form-control"} value={userSurname} onChange={(e) => setUserSurname(e.target.value)} />
                                    </div>

                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("system_settings.user_email")}</label>
                                        <input type={"text"} className={"form-control"} value={userEmailAddress} onChange={(e) => setUserEmailAddress(e.target.value)} />
                                    </div>

                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("system_settings.user_phone_number")}</label>
                                        <input type={"text"} className={"form-control"} value={userTelephone} onChange={(e) => setUserTelephone(e.target.value)} />
                                    </div>

                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("system_settings.user_user_type")}</label>
                                        <select className={"form-select"} value={userRoleId} onChange={(e) => setUserRoleId(e.target.value)}>
                                            { userRoles.map((role) => (
                                                <option value={role.id}>{role.name}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className={"col-12 col-md-6"} data-comment={"Spacer"} />

                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("system_settings.user_profession")}</label>
                                        <input type={"text"} className={"form-control"} value={userJobTitle} onChange={(e) => setUserJobTitle(e.target.value)} />
                                    </div>

                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("system_settings.user_company")}</label>
                                        <input type={"text"} className={"form-control"} value={userCompany} onChange={(e) => setUserCompany(e.target.value)} />
                                    </div>
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col-12 text-center"}>
                                        {submitButton}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )

}